document.addEventListener("turbolinks:load", () => {
  var submit_btn = $("#test-comparison").find('input[type=submit]')
  var limit = 2;

  submit_btn.prop('disabled', true);
  

  $('input.single-checkbox').on('change', function (evt) {
    var checkbox_checked = $("#test-comparison").find('input[type=checkbox]:checked').length;

    if (checkbox_checked > limit) {
      this.checked = false;
    }

    if ($("#test-comparison").find('input[type=checkbox]:checked').length === limit) {
      submit_btn.prop('disabled', false);
    } else {
      submit_btn.prop('disabled', true);
    }
  });
});