(function ($){
    $.fn.counter = function(isFloat) {
    
      parseNumber = function(data, isFloat) {
        if (isFloat) {
          return parseFloat(data);
        } else {
          return parseInt(data);
        }
      };
      
      const $this = $(this),
      numberFrom = parseNumber($this.attr('data-from'), isFloat),
      numberTo = parseNumber($this.attr('data-to'), isFloat),
      delta = numberTo - numberFrom,
      deltaPositive = delta > 0 ? 1 : 0,
      time = parseInt($this.attr('data-time')),
      changeTime = 10;
      
      let currentNumber = numberFrom,
      value = delta*changeTime/time;
      var interval1;
      const changeNumber = () => {
        currentNumber += value;
        //checks if currentNumber reached numberTo
        (deltaPositive && currentNumber >= numberTo) || (!deltaPositive &&currentNumber<= numberTo) ? currentNumber=numberTo : currentNumber;
        if (isFloat) {
            this.text(parseFloat(currentNumber).toFixed(2));
        } else {
            this.text(parseInt(currentNumber));
        }
        currentNumber == numberTo ? clearInterval(interval1) : currentNumber;  
      }
  
      interval1 = setInterval(changeNumber,changeTime);
    }
  }(jQuery));
  
  $(document).ready(function(){
  
    $('.count-int1').counter(false);
    $('.count-int2').counter(false);
    $('.count-int3').counter(false);
    $('.count-float').counter(true);
    
    new WOW().init();
    
    setTimeout(function () {
      $('.count5').counter();
    }, 3000);
  });